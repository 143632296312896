<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md6>
            <v-card class="elevation-12">
              <v-toolbar dark color="success">
                <v-toolbar-title class="mx-auto">
                  {{ $t("RegisterCustomer") }}
                </v-toolbar-title>
              </v-toolbar>
              <v-stepper v-model="step" elevation="0">
                <v-stepper-header>
                  <v-stepper-step
                    v-for="(tab, index) in tabs"
                    :key="index"
                    :complete="step > index"
                    :step="index + 1"
                    :editable="step > index"
                  >
                    {{ tab.title }}
                  </v-stepper-step>
                </v-stepper-header>
                <v-stepper-items>
                  <v-stepper-content
                    v-for="(tab, index) in tabs"
                    :key="index"
                    :step="index + 1"
                  >
                    <v-card-text>

                      <div style="margin:0;padding:0;">
                  <v-img
                    class="mx-auto"
                    style="width: 150px; height: 100px"
                    src="/assets/logo.jpeg"
                  ></v-img>
                </div>

                      <v-form v-if="step === index + 1">
                        <div  v-if="tab.title === $t('PrimaryData')"
                          class="my-0 py-0"
                          :class="{
                            'has-error': errors.has('addEditValidation'),
                          }"
                        >
                          <h4 class="mb-2 mt-5">
                            {{ $t("PrimaryData") }}
                            <span class="required">*</span>
                          </h4>

                          <v-row>
                            <v-col cols="6">
                              <div :class="{'has-error': errors.has(`addEditValidation.firstName`)}">
                                <v-text-field dense  class="mb-2" outlined
                                    name="firstName"  :label="$t('FirstName')"  v-validate="'required'"
                                    data-vv-scope="addEditValidation"  :data-vv-as="$t('FirstName')"
                                    v-model="primaryData.firstName"  hide-details
                                ></v-text-field>
                                <div class="help-block" v-if="errors.has(`addEditValidation.firstName`)">
                                  {{ errors.first(`addEditValidation.firstName`) }}
                                </div>
                              </div>
                            </v-col>

                            <v-col cols="6">
                              <div :class="{'has-error': errors.has(`addEditValidation.secondName`)}">
                              <v-text-field
                                dense  class="mb-2" outlined
                                name="secondName"  :label="$t('SecondName')"  v-validate="'required'"
                                data-vv-scope="addEditValidation"  :data-vv-as="$t('SecondName')"
                                v-model="primaryData.secondName"  hide-details
                              ></v-text-field>
                                <div class="help-block" v-if="errors.has(`addEditValidation.secondName`)">
                                  {{ errors.first(`addEditValidation.secondName`) }}
                                </div>
                              </div>
                            </v-col>

                            <v-col cols="6">
                              <div :class="{'has-error': errors.has(`addEditValidation.thirdName`)}">
                                <v-text-field
                                  dense  class="mb-2" outlined
                                  name="thirdName"  :label="$t('ThirdName')"  v-validate="'required'"
                                  data-vv-scope="addEditValidation"  :data-vv-as="$t('ThirdName')"
                                  v-model="primaryData.thirdName"  hide-details
                                ></v-text-field>

                                <div class="help-block" v-if="errors.has(`addEditValidation.thirdName`)">
                                  {{ errors.first(`addEditValidation.thirdName`) }}
                                </div>
                              </div>
                            </v-col>


                            <v-col cols="6">
                              <div :class="{'has-error': errors.has(`addEditValidation.nickname`)}">
                              <v-text-field
                                dense  class="mb-2" outlined
                                name="nickname"  :label="$t('Nickname')"
                                data-vv-scope="addEditValidation"  :data-vv-as="$t('Nickname')"
                                v-model="primaryData.nickname"  hide-details
                              ></v-text-field>
                                <div class="help-block" v-if="errors.has(`addEditValidation.nickname`)">
                                  {{ errors.first(`addEditValidation.nickname`) }}
                                </div>
                              </div>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="6">
                              <div :class="{'has-error': errors.has(`addEditValidation.phone`)}">
                              <v-text-field
                                  dense class="mb-2"
                                  outlined name="phone"
                                  :label="$t('Phone')"  v-validate="'required'"
                                  data-vv-scope="addEditValidation" :data-vv-as="$t('Phone')"
                                  v-model="primaryData.phone" hide-details
                              ></v-text-field>
                                <div class="help-block" v-if="errors.has(`addEditValidation.phone`)">
                                  {{ errors.first(`addEditValidation.phone`) }}
                                </div>
                              </div>
                            </v-col>

                            <v-col cols="6">
                              <div :class="{'has-error': errors.has(`addEditValidation.email`)}">
                              <v-text-field
                                dense class="mb-2" outlined
                                name="email" :label="$t('Email')" v-validate="'required|email'"
                                data-vv-scope="addEditValidation" :data-vv-as="$t('Email')"
                                v-model="primaryData.email" hide-details
                              ></v-text-field>
                                <div class="help-block" v-if="errors.has(`addEditValidation.email`)">
                                  {{ errors.first(`addEditValidation.email`) }}
                                </div>
                              </div>
                            </v-col>

                            <v-col cols="6">
                              <div :class="{'has-error': errors.has(`addEditValidation.birthday`)}">
                              <el-date-picker  style="width:100%"
                                dense  class="mb-2" outlined
                                name="birthday" :placeholder="$t('Birthday')"
                                type="date"  v-validate="'required'" data-vv-scope="addEditValidation"
                                :data-vv-as="$t('Birthday')" v-model="primaryData.birthday" hide-details
                              ></el-date-picker>
                                <div class="help-block" v-if="errors.has(`addEditValidation.birthday`)">
                                  {{ errors.first(`addEditValidation.birthday`) }}
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                        </div>

                        <div v-if="tab.title === $t('MeasurementData')"
                          class="py-0"
                          :class="{
                            'has-error': errors.has('addEditValidation'),
                          }"
                        >
                          <h4 class="mb-2 mt-5">
                            {{ $t("MeasurementData") }}
                            <span class="required">*</span>
                          </h4>

                          <v-row>
                            <v-col cols="6">
                              <div :class="{'has-error': errors.has(`addEditValidation.height`)}">
                                <v-text-field
                                    dense  class="mb-2"  outlined
                                    name="height" :label="$t('Height')"  v-validate="'required'"
                                    data-vv-scope="addEditValidation" :data-vv-as="$t('Height')"
                                    v-model="MeasurementData.height"  hide-details
                                ></v-text-field>
                                <div class="help-block" v-if="errors.has(`addEditValidation.height`)">
                                  {{ errors.first(`addEditValidation.height`) }}
                                </div>
                              </div>
                            </v-col>

                            <v-col cols="6">
                              <div :class="{'has-error': errors.has(`addEditValidation.weight`)}">
                                <v-text-field
                                    dense class="mb-2"  outlined
                                    name="weight" :label="$t('Weight')"
                                    v-validate="'required'"  data-vv-scope="addEditValidation"
                                    :data-vv-as="$t('Weight')" v-model="MeasurementData.weight" hide-details
                                ></v-text-field>
                                <div class="help-block" v-if="errors.has(`addEditValidation.weight`)">
                                {{ errors.first(`addEditValidation.weight`) }}
                                </div>
                              </div>
                            </v-col>

                            <v-col cols="6">
                              <div :class="{'has-error': errors.has(`addEditValidation.gender`)}">
                                <v-select dense  class="mb-2" outlined  name="gender" :label="$t('Gender')"
                                    :items="genderOptions" v-validate="'required'"
                                    data-vv-scope="addEditValidation" :data-vv-as="$t('Gender')"
                                    v-model="MeasurementData.gender" hide-details
                                ></v-select>
                                <div class="help-block" v-if="errors.has(`addEditValidation.gender`)">
                                  {{ errors.first(`addEditValidation.gender`) }}
                                </div>
                              </div>
                            </v-col>

                          </v-row>
                        </div>

                        <div v-if="tab.title === $t('personDesired')"
                          class="py-0"
                          :class="{
                            'has-error': errors.has('addEditValidation'),
                          }"
                        >
                          <h4 class="mb-2 mt-5">
                            {{ $t("personDesired") }}
                            <span class="required">*</span>
                          </h4>
                          <v-row>

                            <v-col cols="6 my-2">
                              <div :class="{'has-error': errors.has(`addEditValidation.Goal`)}">
                                <label>{{ $t("Goal") }}</label>
                                <v-autocomplete class="d-block my-2" name="goal"
                                    data-vv-scope="addEditValidation"  v-validate="'required'"
                                    :data-vv-as="$t('Goal')" hide-details
                                    dense outlined v-model="personDesired.goal"
                                    item-value="id" item-text="name" :items="goalList" clearable
                                    @change="handleGoalChange"
                                ></v-autocomplete>
                              </div>

                                <div class="help-block" v-if="errors.has(`addEditValidation.goal`)">
                                  {{ errors.first(`addEditValidation.goal`) }}
                                </div>
                            </v-col>

                            <v-col cols="6 my-2">
                              <div :class="{'has-error': errors.has(`addEditValidation.protein`)}">
                                <label>{{ $t("Protein") }}</label>
                                <v-text-field type="number"
                                    dense class="d-block my-2"  outlined name="protein"
                                    :label="$t('Protein')"  data-vv-scope="addEditValidation"
                                    :data-vv-as="$t('Protein')"  v-model="personDesired.protein" hide-details
                                    v-validate="proteinValidationRule"
                                ></v-text-field>
                                <div class="help-block" v-if="errors.has(`addEditValidation.protein`)">
                                  {{ errors.first(`addEditValidation.protein`) }}
                                </div>
                              </div>
                            </v-col>

                            <v-col cols="6">
                              <div :class="{'has-error': errors.has(`addEditValidation.carb`)}">
                              <v-text-field dense  class="mb-2" outlined type="number"
                                  name="carb" :label="$t('Carb')"
                                  data-vv-scope="addEditValidation"  :data-vv-as="$t('Carb')"
                                  v-model="personDesired.carb" hide-details
                                  v-validate="carbValidationRule"
                              ></v-text-field>
                                <div class="help-block" v-if="errors.has(`addEditValidation.carb`)">
                                  {{ errors.first(`addEditValidation.carb`) }}
                                </div>
                              </div>
                            </v-col>

                            <v-col cols="6">
                              <div :class="{'has-error': errors.has(`addEditValidation.notes`)}">
                                <v-textarea dense  class="mb-2"  outlined name="notes"
                                            :label="$t('Notes')" v-model="personDesired.notes"  hide-details
                                ></v-textarea>
                                <div class="help-block" v-if="errors.has(`addEditValidation.notes`)">
                                  {{ errors.first(`addEditValidation.notes`) }}
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                        </div>

                        <div v-if="tab.title === $t('Contact information')" class="py-0" :class="{ 'has-error': errors.has('addEditValidation') }">
                          <h4 class="mb-2 mt-5">
                            {{ $t("Contact information") }}
                            <span class="required">*</span>
                          </h4>

                          <v-row>
                            <v-col  :cols="6">
                              <div :class="{'has-error': errors.has(`addEditValidation.City`)}">
                                <label>{{ $t("City") }}</label>
                                <v-autocomplete
                                    class="d-block my-2"
                                    name="City" data-vv-scope="addEditValidation"  v-validate="'required'"
                                    :data-vv-as="$t('City')"  hide-details
                                    dense outlined v-model="contact_us.city_id" item-value="id"
                                    item-text="name" :items="cityList"
                                    @change="getNeighborhoodList(contact_us.city_id)" clearable
                                ></v-autocomplete>
                              </div>
                              <div class="help-block" v-if="errors.has(`addEditValidation.City`)">
                                {{ errors.first(`addEditValidation.City`) }}
                              </div>
                            </v-col>

                            <v-col   :cols="6">
                              <div :class="{'has-error': errors.has(`addEditValidation.Neighborhood`)}">
                                <label>{{ $t("Neighborhood") }}</label>
                                <v-autocomplete
                                    class="d-block my-2" name="Neighborhood" data-vv-scope="addEditValidation"
                                     v-validate="'required'" :data-vv-as="$t('Neighborhood')" hide-details dense outlined
                                    v-model="contact_us.branch_id" item-value="id" item-text="name" :items="neighborhoodList" clearable
                                    @input="updateBranchName"
                                ></v-autocomplete>
                              </div>

                              <div class="help-block" v-if="errors.has(`addEditValidation.Neighborhood`)">
                                {{ errors.first(`addEditValidation.Neighborhood`) }}
                              </div>
                            </v-col>

                            <v-col   :cols="6">
                              <div :class="{'has-error': errors.has(`addEditValidation.Period`)}">
                                <label>{{ $t("Period") }}</label>
                                <v-autocomplete
                                    class="d-block my-2" name="Period"
                                    data-vv-scope="addEditValidation"  v-validate="'required'"
                                    :data-vv-as="$t('Period')" hide-details
                                    dense outlined v-model="contact_us.period"
                                    item-value="id" item-text="name"
                                    :items="periodList" clearable
                                ></v-autocomplete>
                              </div>

                              <div class="help-block" v-if="errors.has(`addEditValidation.Period`)">
                                {{ errors.first(`addEditValidation.Period`) }}
                              </div>
                            </v-col>

                            <v-col   :cols="6">
                              <div :class="{'has-error': errors.has(`addEditValidation.HomeNumber`)}">
                                <label>{{ $t("HomeNumber") }}</label>
                                <v-text-field
                                    class="d-block my-2" type="text" name="HomeNumber"
                                    data-vv-scope="addEditValidation"
                                    :data-vv-as="$t('HomeNumber')" hide-details
                                    dense outlined v-model="contact_us.home_number"
                                ></v-text-field>
                              </div>

                              <div class="help-block" v-if="errors.has(`addEditValidation.HomeNumber`)">
                                {{ errors.first(`addEditValidation.HomeNumber`) }}
                              </div>
                            </v-col>

                            <v-col   :cols="6">
                              <div :class="{'has-error': errors.has(`addEditValidation.company`)}">
                                <label>{{ $t("Company") }}</label>
                                <v-autocomplete
                                    class="d-block my-2" name="company"
                                    data-vv-scope="addEditValidation"  :data-vv-as="$t('Company')"
                                    hide-details dense outlined v-model="contact_us.company_id"
                                    item-value="id" item-text="name" :items="companyList" clearable
                                    @input="updateCompanyName"
                                ></v-autocomplete>
                              </div>

                              <div class="help-block" v-if="errors.has(`addEditValidation.company`)">
                                {{ errors.first(`addEditValidation.company`) }}
                              </div>
                            </v-col>


                            <v-col   :cols="6">
                              <div :class="{'has-error': errors.has(`addEditValidation.address`) }">
                                <label>{{ $t("Address") }}</label>
                                <v-text-field
                                    class="d-block my-2" type="text"
                                    name="address" data-vv-scope="addEditValidation"
                                     v-validate="'required'" :data-vv-as="$t('Address')" hide-details
                                    dense outlined v-model="contact_us.address"
                                ></v-text-field>
                              </div>

                              <div class="help-block" v-if="errors.has(`addEditValidation.address`)">
                                {{ errors.first(`addEditValidation.address`) }}
                              </div>
                            </v-col>

                            <v-col   :cols="6">
                              <div :class="{'has-error': errors.has(`addEditValidation.notes2`)}">
                                <label>{{ $t("DeliveryMethod") }}</label>
                                <v-textarea
                                    class="d-block my-2" type="text" name="notes2"
                                    rows="4" data-vv-scope="addEditValidation"
                                     v-validate="'required'" :data-vv-as="$t('DeliveryMethod')" hide-details dense outlined
                                    v-model="contact_us.notes"
                                ></v-textarea>
                              </div>

                              <div class="help-block" v-if="errors.has(`addEditValidation.notes2`)" >
                                {{ errors.first(`addEditValidation.notes2`) }}
                              </div>
                            </v-col>

                            <v-col   :cols="6">
                              <div :class="{'has-error': errors.has(`addEditValidation.location`)}">
                                <label>{{ $t("location_google_map") }}</label>
                                <v-text-field
                                    class="d-block my-2" type="text"
                                    name="location" data-vv-scope="addEditValidation"
                                     v-validate="'required'" :data-vv-as="$t('location')" hide-details
                                    dense outlined v-model="contact_us.location"
                                ></v-text-field>
                              </div>

                              <div class="help-block" v-if="errors.has(`addEditValidation.location`)" >
                                {{ errors.first(`addEditValidation.location`) }}
                              </div>
                            </v-col>

                            <v-col   :cols="6">
                              <div style="margin-top: 33px" :class="{'has-error': errors.has(`addEditValidation.location_image`)}">
                                <label for="fileInput" class="custom-file-upload d-block my-2">
                                  <i class="custom-icon"></i> {{ $t("location_image") }}
                                </label>
                                <input type="file" id="fileInput" @change="uploadLocationImage"
                                    accept=".jpg,.jpeg,.png" ref="location_image" name="location_image"
                                    style="display: none" data-vv-scope="addEditValidation"
                                       v-validate="'required'" :data-vv-as="$t('location_image')"
                                />
                                <div class="help-block" v-if="errors.has(`addEditValidation.location_image`)" >
                                  {{ errors.first(`addEditValidation.location_image`) }}
                                </div>
                              </div>
                            </v-col>


                          </v-row>
                        </div>

                        <div v-if="tab.title === $t('Subscription data')" class="py-0" :class="{'has-error': errors.has('addEditValidation')}">
                          <h4 class="mb-2 mt-5">
                            {{ $t("Subscription data") }}
                            <span class="required">*</span>
                          </h4>
                          <v-card-text>
                            <v-container fluid>
                              <v-row>
                                <v-col class="py-0" :cols="6">
                                  <div :class="{'has-error': errors.has(`addEditValidation.Package`)}">
                                    <label>{{ $t("Package") }}</label>
                                    <v-autocomplete class="d-block my-2"
                                        name="Package" data-vv-scope="addEditValidation"
                                        v-validate="'required'" :data-vv-as="$t('Package')" hide-details dense outlined
                                        v-model="subscribe.package_id" item-value="id"
                                        item-text="name" :items="packageList" clearable
                                        @change="changePackage(subscribe.package_id)"
                                    ></v-autocomplete>
                                  </div>

                                  <div class="help-block" v-if="errors.has(`addEditValidation.Package`)">
                                    {{ errors.first(`addEditValidation.Package`) }}
                                  </div>
                                </v-col>

                                <template v-if="packageDetails">
                                  <v-col class="py-0" :cols="6">
                                    <div :class="{ 'has-error': errors.has(`addEditValidation.price`),  }">
                                      <label>{{ $t("Price") }}</label>
                                      <v-text-field
                                          class="d-block my-2"
                                          type="text" name="price" data-vv-scope="addEditValidation"  v-validate="'required'"
                                          disabled :data-vv-as="$t('Price')" hide-details
                                          dense outlined v-model="packageDetails.price"
                                      ></v-text-field>
                                    </div>

                                    <div class="help-block"  v-if="errors.has(`addEditValidation.price`)" >
                                      {{ errors.first(`addEditValidation.price`) }}
                                    </div>
                                  </v-col>




                                  <v-col class="py-0" :cols="6">
                                    <div  :class="{ 'has-error': errors.has(`addEditValidation.days`),  }">
                                      <label>{{ $t("DaysSelected") }}</label>
                                      <v-autocomplete
                                          class="d-block my-2" name="days"
                                          data-vv-scope="addEditValidation"
                                          v-validate="'required'" :data-vv-as="$t('DaysSelected')"
                                          hide-details dense outlined v-model="subscribe.days"
                                          item-value="id" item-text="name" :items="dayList" @change="dayListChanged" multiple clearable
                                      ></v-autocomplete>
                                    </div>

                                    <div  class="help-block" v-if="errors.has(`addEditValidation.days`)" >
                                      {{ errors.first(`addEditValidation.days`) }}
                                    </div>
                                  </v-col>

                                  <v-col class="py-0" :cols="6">
                                    <div  :class="{ 'has-error': errors.has(`addEditValidation.start_date`),  }"  >
                                      <label>{{ $t("StartDate") }}</label>
                                      <el-date-picker style="width: 100%" :disabled="$route.params.id" v-model="subscribe.start_date"
                                          type="date" class="mt-2"  data-vv-scope="addEditValidation" name="start_date"
                                          v-validate="'required'" :data-vv-as="$t('StartDate')"
                                                      :picker-options="pickerOptions"
                                      >
                                      </el-date-picker>
                                    </div>

                                    <div class="help-block" v-if="errors.has(`addEditValidation.start_date`)"  >
                                      {{ errors.first(`addEditValidation.start_date`) }}
                                    </div>
                                  </v-col>

                                  <v-col class="py-0" :cols="12">
                                    <div style="display: flex; align-items: center;">
                                      <v-checkbox @change="changeOfficialVacations(subscribe.check_official_vacations)"
                                          v-model="subscribe.check_official_vacations">
                                      </v-checkbox>
                                      <label>{{ $t("question_check_official_vacations") }}</label>
                                    </div>
                                  </v-col>



                                  <v-container fluid>
                                    <table id="pacakgeFeaturesTable">
                                      <tr>
                                        <th>#</th>
                                        <th>اسم المجموعة</th>
                                        <th>الكمية</th>
                                      </tr>

                                      <tr v-for="(l, lIndex) in subscribe.group_subscribe" :key="lIndex">
                                        <td> {{ lIndex + 1 }}</td>
                                        <td>
                                          <div>
                                            <label>{{ $t("Group") }}</label>
                                            <v-text-field class="d-block my-2" disabled  hide-details dense outlined v-model="l.group_name"></v-text-field>
                                          </div>
                                        </td>
                                        <td>
                                          <div>
                                            <label>{{ $t("Quantity") }}</label>
                                            <v-text-field disabled class="d-block my-2" hide-details dense outlined v-model="l.quantity"></v-text-field>
                                          </div>
                                        </td>
                                      </tr>
                                    </table>
                                  </v-container>
                                </template>
                              </v-row>
                            </v-container>
                          </v-card-text>
                        </div>


                        <div v-if="tab.title === $t('PaymentDetails')"
                            class="py-0"
                            :class="{
                            'has-error': errors.has('addEditValidation'),
                          }"
                        >
                          <h4 class="mb-2 mt-5">
                            {{ $t("PaymentDetails") }}
                            <span class="required">*</span>
                          </h4>

                          <v-row>
                            <v-col   :cols="6">
                              <div :class="{'has-error': errors.has(`addEditValidation.bank_transfer`)}">
                                <label>{{ $t("PaymentBank") }}</label>
                                <v-text-field :value="$t('bank_transfer')" class="d-block my-2" disabled
                                              name="bank_transfer" hide-details dense outlined>
                                </v-text-field>
                              </div>
                              <div class="help-block" v-if="errors.has(`addEditValidation.bank_transfer`)">
                                {{ errors.first(`addEditValidation.bank_transfer`) }}
                              </div>
                            </v-col>

                            <v-col   :cols="6">
                              <div :class="{'has-error': errors.has(`addEditValidation.bank_account_number`)}">
                                <label>{{ $t("bank_account_number") }}</label>
                                <v-text-field class="d-block my-2" disabled v-model="payment_details.bank_account_number"
                                              name="bank_account_number" hide-details dense outlined>
                                </v-text-field>
                              </div>
                              <div class="help-block" v-if="errors.has(`addEditValidation.bank_account_number`)">
                                {{ errors.first(`addEditValidation.bank_account_number`) }}
                              </div>
                            </v-col>

                            <v-col :cols="isMobileView ? 12 : 6">
                              <div :class="{'has-error': errors.has(`addEditValidation.iban`)}">
                                <label>{{ $t("iban") }}</label>
                                <v-text-field disabled
                                    class="d-block my-2" type="text" name="iban"
                                    data-vv-scope="addEditValidation"
                                    :data-vv-as="$t('iban')" hide-details
                                    dense outlined v-model="payment_details.iban"
                                ></v-text-field>
                              </div>

                              <div class="help-block" v-if="errors.has(`addEditValidation.iban`)">
                                {{ errors.first(`addEditValidation.iban`) }}
                              </div>
                            </v-col>

                            <v-col   :cols="6">
                              <div :class="{'has-error': errors.has(`addEditValidation.bank`)}">
                                <label>{{ $t("bank") }}</label>
                                <v-text-field disabled
                                    class="d-block my-2" type="text" name="iban"
                                    data-vv-scope="addEditValidation"
                                    :data-vv-as="$t('bank')" hide-details
                                    dense outlined v-model="payment_details.bank"
                                ></v-text-field>
                              </div>

                              <div class="help-block" v-if="errors.has(`addEditValidation.bank`)">
                                {{ errors.first(`addEditValidation.bank`) }}
                              </div>
                            </v-col>

                            <v-col class="py-0" :cols="6">
                              <div  :class="{ 'has-error': errors.has(`addEditValidation.date_of_transaction`),  }"  >
                                <label>{{ $t("date_of_transaction") }}</label>

                                <el-date-picker style="width: 100%" :disabled="$route.params.id" v-model="payment_details.date_of_transaction"
                                                type="date" class="mt-2"  data-vv-scope="addEditValidation" name="date_of_transaction"
                                                v-validate="'required'" :data-vv-as="$t('date_of_transaction')"
                                >
                                </el-date-picker>

                              </div>

                              <div class="help-block" v-if="errors.has(`addEditValidation.date_of_transaction`)"  >
                                {{ errors.first(`addEditValidation.date_of_transaction`) }}
                              </div>
                            </v-col>

                            <v-col   :cols="6">
                              <div style="margin-top: 20px" :class="{'has-error': errors.has(`addEditValidation.sms_discount_message`) }">
                                <label for="sms_discount_message" class="custom-file-upload d-block my-2">
                                  <i class="custom-icon"></i> {{ $t("sms_discount_message") }}
                                </label>
                                <input type="file" id="sms_discount_message" @change="uploadSmsDiscountMessage"
                                    accept=".jpg,.jpeg,.png, .pdf" ref="sms_discount_message" data-vv-scope="addEditValidation"
                                       name="sms_discount_message" v-validate="'required'" :data-vv-as="$t('sms_discount_message')"
                                    style="display: none"
                                />

                                <div class="help-block" v-if="errors.has(`addEditValidation.sms_discount_message`)">
                                  {{ errors.first(`addEditValidation.sms_discount_message`) }}
                                </div>
                              </div>
                            </v-col>

                            <v-col   :cols="6">
                              <div style="margin-top: 33px" :class="{'has-error': errors.has(`addEditValidation.pass_receipt_image`) }">
                                <label for="pass_receipt_image" class="custom-file-upload d-block my-2">
                                  <i class="custom-icon"></i> {{ $t("pass_receipt_image") }}
                                </label>
                                <input type="file" id="pass_receipt_image" @change="uploadReceiptImage"
                                    accept=".jpg,.jpeg,.png, .pdf" ref="receipt_image" data-vv-scope="addEditValidation"
                                       name="pass_receipt_image" v-validate="'required'" :data-vv-as="$t('pass_receipt_image')"
                                    style="display: none"
                                />

                                <div class="help-block" v-if="errors.has(`addEditValidation.pass_receipt_image`)">
                                  {{ errors.first(`addEditValidation.pass_receipt_image`) }}
                                </div>
                              </div>
                            </v-col>

                          </v-row>
                        </div>
                      </v-form>
                    </v-card-text>
                    <v-card-actions class="py-0 d-flex justify-center">
                      <v-btn
                        v-if="step !== 1"
                        class="mr-4"
                        small
                        outlined
                        color="info"
                        @click="prevStep"
                        >{{ $t("Previous") }}</v-btn
                      >
                      <div class="flex-grow"></div>
                      <v-btn
                        v-if="step !== tabs.length"
                        small
                        outlined
                        color="info"
                        @click="nextStep"
                        >{{ $t("Next") }}</v-btn
                      >
                      <v-btn v-if="step === tabs.length"
                              large outlined
                             color="success" @click="register"
                      >{{ $t("register") }}</v-btn>
                    </v-card-actions>

                    <BlockUI v-if ="loadClicked" :message="msg">
                      <div class="sk-wave">
                        <div class="sk-rect sk-rect1"></div>
                        <div class="sk-rect sk-rect2"></div>
                        <div class="sk-rect sk-rect3"></div>
                        <div class="sk-rect sk-rect4"></div>
                        <div class="sk-rect sk-rect5"></div>
                      </div>
                    </BlockUI>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import moment from "moment/moment";

export default {
  name: "App",
  data: () => ({
    loadClicked: false,
    msg: 'برجاء الانتظار لحين اكتمال العملية',
    isMobileView: window.innerWidth <= 600,
    step: 1,
    companyList: [],
    tabs: [],
    cityList: [],
    neighborhoodList: [],
    packageList: [],
    primaryData: {
      firstName: "",
      secondName: "",
      thirdName: "",
      nickname: "",
      email: "",
      birthday: "",
      phone: "",
    },
    MeasurementData: {
      gender: "",
      height: "",
      weight: "",

    },
    personDesired: {
      goal: "",
      carb: "",
      protein: "",
      notes: "",
    },
    contact_us: {
      city_id: null,
      city_name: null,
      branch_id: null,
      branch_name: null,
      period: null,
      home_number: null,
      company_id: "",
      company_name: null,
      address: null,
      notes: null,
      location: null,
      location_image: null,
    },
    payment_details:{
      payment_method : "bank_transfer",
      iban : "SA 2805000068203234898000",
      bank_account_number : "68203234898000",
      receipt_image : null,
      sms_discount_message: null,
      bank : "بنك الإنماء",
      date_of_transaction : null
    },
    genderOptions: [],
    type: 1,
    periodList: [
      {
        id: 'am',
        name: "صباحي",
      },
      {
        id: 'pm',
        name: "مسائي",
      },
    ],
    goalList: [
      {
        id: 'bigger',
        name: "تضخيم",
      },
      {
        id: 'drying',
        name: "تنشيف",
      },
      {
        id: 'weight_loss',
        name: "إنقاص وزن",
      },
    ],
    maxValues: {
      weight_loss: { protein: 100, carb: 100 },
      bigger: { protein: 150, carb: 150 },
      drying: { protein: 150, carb: 75 },
    },
    maxProteinValue: null,
    maxCarbValue: null,
    subscribe: {
      package_id: 1,
      package_name: null,
      day_status: 1,
      days: [],
      days_names: [],
      start_date: null,
      number_of_days: null,
      group_subscribe: [],
      check_official_vacations: false
    },
    pickerOptions: {
      disabledDate: (date) => {
        // Get the current date
        const today = new Date();
        // Set hours, minutes, seconds, and milliseconds to 0
        today.setHours(0, 0, 0, 0);

        // Calculate the date 2 days from now
        const twoDaysFromNow = new Date(today);
        twoDaysFromNow.setDate(today.getDate() + 2);

        // Disable dates after 2 days from the current date
        return date < twoDaysFromNow;
      }
    },
    packageDetails: null,
    dayList: [],
    listGroup: [],
    object: {
      mobile: null,
      password: null,
    },
  }),
  computed: {
    proteinValidationRule() {
      let rule = 'required';
      rule += `|max_value:${this.maxProteinValue}`;
      rule += `|min_value:0`;
      return rule;
    },
    carbValidationRule() {
      let rule = 'required';
      rule += `|max_value:${this.maxCarbValue}`;
      rule += `|min_value:0`;
      return rule;
    },
  },
  methods: {
    updateIsMobileView() {
      this.isMobileView = window.innerWidth <= 600; // Adjust the breakpoint as needed
    },
    nextStep() {
      this.$validator.validateAll("addEditValidation").then(($valid) => $valid ? this.step++ : null);
    },
    prevStep() {
      this.step--;
    },
    setActive(type) {
      this.type = type;
    },
    updateBranchName() {
      const selectedBranch = this.neighborhoodList.find(branch => branch.id === this.contact_us.branch_id);
      this.contact_us.branch_name = selectedBranch ? selectedBranch.name : '';
    },
    updateCompanyName() {
      const selectedCompany = this.companyList.find(company => company.id === this.contact_us.company_id);
      this.contact_us.company_name = selectedCompany ? selectedCompany.name : '';
    },
    handleGoalChange(selectedGoal) {
      const { protein, carb } = this.maxValues[selectedGoal] || {};
      // this.maxProteinValue = protein || null;
      // this.maxCarbValue = carb || null;
      this.maxProteinValue = 150;
      this.maxCarbValue    = 200;
      this.personDesired.protein = protein;
      this.personDesired.carb = carb;
    },
    register() {


      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) {
           return;
        }
        this.loadClicked= true;


        let formData = new FormData();
        const formSections = [
          { section: 'primary_data', data: this.primaryData },
          { section: 'measurement_data', data: this.MeasurementData },
          { section: 'person_desired', data: this.personDesired },
          { section: 'contact_us', data: this.contact_us },
          { section: 'subscribe', data: this.subscribe },
          { section: 'payment_details', data: this.payment_details },
        ];

        formSections.forEach((formSection) => {
          const { section, data } = formSection;
          for (const key in data) {
            if (data.hasOwnProperty(key)) {
              if (key === 'birthday'|| key === 'start_date'|| key === 'date_of_transaction') {
                formData.append(`${section}[${key}]`, moment(data[key]).format('YYYY-MM-DD'));
              } else if (key === 'location_image' || key === 'receipt_image') {
                if (data[key] !== null) {
                  formData.append(`${section}[${key}]`, data[key], data[key].name);
                }
              }else if (key === 'group_subscribe') {
                formData.append(`${section}[${key}]`, JSON.stringify(data[key]));
              } else {
                formData.append(`${section}[${key}]`, data[key]);
              }
            }
          }
        });

          this.$store.dispatch("auth/registerCustomer", formData).then((result) => {
            this.notifySuccess("register_customer_success",result)
            window.location.href = "/landing/customer_success";
          }).catch(() => {
            this.loadClicked = false
          }).finally(() => {
            this.loadClicked = false
          });
      });
    },
    getNeighborhoodList(id) {
      const selectedCity = this.cityList.find(city => city.id === this.contact_us.city_id);
      this.contact_us.city_name = selectedCity ? selectedCity.name : '';
      this.$store.dispatch(`subscribe/subCountryList`, selectedCity.id).then((res) => {
        this.neighborhoodList = res.data;
      });
    },

    changePackage(id) {
      if (id)
      this.$store.dispatch(`subscribe/packageDetails`, id).then((res) => {
        this.packageDetails = res.data;
        this.subscribe.days = res.data.days;
        this.subscribe.package_name = res.data.name.ar;
        this.subscribe.group_subscribe = res.data.details;
        this.subscribe.number_of_days = res.data.number_of_days;
        this.dayListChanged()
      })
    },
    dayListChanged() {
      this.subscribe.days_names = this.dayList.filter(day => this.subscribe.days.includes(day.id))
                                              .map(day => day.name);
    },
    changeOfficialVacations(){
      this.subscribe.check_official_vacations !== this.subscribe.check_official_vacations;
    },
    uploadLocationImage() {
      const fileInput = this.$refs.location_image;
      const file = fileInput[0].files[0];

      if (!file) {
        this.notifyError("Please select a file.");
        return;
      }

      if (!this.isValidFile(file)) {
        this.notifyError(this.$t("invalid_image_and_pdf"));
        return;
      }

      this.contact_us.location_image = file;
    },
    uploadReceiptImage() {
      const fileInput = this.$refs.receipt_image;
      const file = fileInput[0].files[0];

      if (!file) {
        this.notifyError("Please select a file.");
        return;
      }

      if (!this.isValidFile(file)) {
        this.notifyError(this.$t("invalid_image_and_pdf"));
        return;
      }

      this.payment_details.receipt_image = file;
    },
    uploadSmsDiscountMessage() {
      const fileInput = this.$refs.sms_discount_message;
      const file = fileInput[0].files[0];

      if (!file) {
        this.notifyError("Please select a file.");
        return;
      }

      if (!this.isValidFile(file)) {
        this.notifyError(this.$t("invalid_image_and_pdf"));
        return;
      }

      this.payment_details.sms_discount_message = file;
    },
    isValidFile(file) {
      // Check if the file is a valid PNG or PDF file
      const validTypes = ["image/jpg","image/jpeg","image/png", "application/pdf"];
      return validTypes.includes(file.type);
    },

  },
  created() {

    this.tabs= [
      { title: this.$t('PrimaryData') },
      { title: this.$t('MeasurementData') },
      { title: this.$t('personDesired') },
      { title: this.$t('Contact information') },
      { title: this.$t('Subscription data')},
      { title: this.$t('PaymentDetails')},
    ]
    this.genderOptions = [
      { text: this.$t('Male'), value: 'male' },
      { text: this.$t('Female'), value: 'female' }
    ];

    this.dayList = [
      { id: 6, name: this.$t("Saturday") },
      { id: 0, name: this.$t("Sunday") },
      { id: 1, name: this.$t("Monday") },
      { id: 2, name: this.$t("Tuesday") },
      { id: 3, name: this.$t("Wedensday") },
      { id: 4, name: this.$t("Thursday") },
    ]
    this.$store.dispatch(`subscribe/countyList`).then((res) => {
      this.cityList = res.data;
    });

    this.$store.dispatch(`subscribe/companyList`).then((res) => {
      this.companyList = res.data;
    });


    this.$store.dispatch(`subscribe/packageList`).then((res) => {
      this.packageList = res.data;
    });

    this.$store.dispatch(`subscribe/packageDetails`, 1).then((res) => {
      this.packageDetails = res.data;
      this.subscribe.days = res.data.days;
      this.subscribe.package_name = res.data.name.ar;
      this.subscribe.group_subscribe = res.data.details;
      this.subscribe.number_of_days = res.data.number_of_days;
      this.subscribe.days_names = this.dayList.filter(day => this.subscribe.days.includes(day.id))
          .map(day => day.name);
    })

  },
  mounted() {
    window.addEventListener('resize', this.updateIsMobileView);

  }
}
</script>


<style>
.btn-gradient-1 {
  width: 100%;
  border-radius: 10px;
  padding: 15px;
  margin: 0;
  background: #fff;
  color: #000;
  border: 1px solid #000 !important;
}

svg path {
  fill: #000 !important;
}

.active-login {
  background: linear-gradient(to right, #4b6cb7, #182848);
  color: white;
}

.active-login svg path {
  fill: #fff !important;
}

.active-login .fa-check {
  display: inline-block;
}

.fa-check {
  display: none;
}


#pacakgeFeaturesTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#pacakgeFeaturesTable td,
#pacakgeFeaturesTable th {
  border: 1px solid #ccc !important;
  padding: 8px;
}

#pacakgeFeaturesTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: right;
  background-color: #04aa6d;
  color: white;
}

.custom-file-upload {
  cursor: pointer;
  display: inline-block;
  padding: 6px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #f8f8f8;
  color: #333;
  text-align: center;
}

.custom-icon::before {
  content: "\f093"; /* Use the desired icon (Font Awesome or other) */
  margin-right: 5px;
}
.flex-grow {
  flex-grow: 1;
}
</style>